<template>
  <v-treeview
    ref="treeview"
    expand-icon=""
    :items="items"
    :load-children="fetchChildren"
    :open.sync="open"
    :open-all="openAll"
    open-on-click
    activatable
    transition
  >
    <template #label="{ item }">
      <projects-item
        :type="item.type"
        :name="item.name"
        :more-info-link="getLink(item)"
        :is-archived="item.isArchived"
        :is-loading="item.isLoading"
        :is-open="checkItemIsOpen(item.id)"
        :access-to-rooms="accessToRooms"
        :has-children="!!item.children"
      />
    </template>
  </v-treeview>
</template>

<script>
// Constants
import { PROJECT, BUILDING, UNIT, ROOM, NO_UNITS, NO_ROOMS, NO_BUILDINGS } from '@/constants/projectEntity';
import { BUILDINGS_DETAILED, PROJECTS_DETAILED, UNITS_DETAILED, ROOMS_DETAILED } from '@/constants/routes';

// Components
import ProjectsItem from '@/components/Projects/Item.vue';

export default {
  name: 'ProjectsList',

  components: { ProjectsItem },

  props: {
    projects: { type: Array, required: true },
    getBuildings: { type: Function, required: true },
    getUnits: { type: Function, required: true },
    getRooms: { type: Function, required: true },
    openAll: { type: Boolean, default: false },
    accessToRooms: { type: Boolean, required: true },
  },

  data() {
    return {
      open: [],
      projectBuildings: {},
      buildingUnits: {},
      unitRooms: {},
    };
  },

  computed: {
    items() {
      if (this.openAll) {
        return this.projects.map(project => {
          if (!project.buildings.length) return project;

          return {
            ...project,
            children: project.buildings.map(building => {
              if (!building.units.length) {
                return {
                  ...building,
                  type: BUILDING,
                  originalId: building.id,
                  id: `${project.id} ${building.id}`,
                };
              }

              return {
                ...building,
                type: BUILDING,
                originalId: building.id,
                id: `${project.id} ${building.id}`,
                children: building.units.map(unit => {
                  if (!unit.rooms.length) {
                    return {
                      ...unit,
                      type: UNIT,
                      originalId: unit.id,
                      id: `${project.id} ${building.id} ${unit.id}`,
                    };
                  }

                  return {
                    ...unit,
                    type: UNIT,
                    originalId: unit.id,
                    id: `${project.id} ${building.id} ${unit.id}`,
                    children: unit.rooms.map(room => {
                      return {
                        ...room,
                        type: ROOM,
                        originalId: room.id,
                        id: `${project.id} ${building.id} ${unit.id} ${room.id}`,
                      };
                    }),
                  };
                }),
              };
            }),
          };
        });
      }

      return this.projects.map(project => ({
        ...project,
        children: this.getProjectChildren(project.id),
      }));
    },
  },

  watch: {
    open(newOpen, oldOpen) {
      const closedItems = oldOpen.filter(open => !newOpen.includes(open));

      closedItems.forEach(closeItem => {
        const idPath = `${closeItem}`.split(' ');

        if (idPath.length === 1) {
          this.projectBuildings[closeItem] = [];
          const treeViewNode = this.$refs.treeview.$children.find(child => child.key === closeItem);
          treeViewNode.$data.hasLoaded = false;
        } else if (idPath.length === 2) {
          this.buildingUnits[closeItem] = [];

          // eslint-disable-next-line eqeqeq
          const parentTreeViewNode = this.$refs.treeview.$children.find(child => child.key == idPath[0]);
          const treeViewNode = parentTreeViewNode.$children.find(child => child.key === closeItem);

          treeViewNode.$data.hasLoaded = false;
        }
      });
    },
  },

  methods: {
    getProjectChildren(projectId) {
      return (
        this.projectBuildings[projectId]?.map(building => ({
          ...building,
          children: this.getBuildingChildren(building.id),
        })) || []
      );
    },

    getBuildingChildren(buildingId) {
      if (this.accessToRooms) {
        return (
          this.buildingUnits[buildingId]?.map(unit => ({
            ...unit,
            children: this.getUnitChildren(unit.id),
          })) || []
        );
      }
      return (
        this.buildingUnits[buildingId]?.map(unit => ({
          ...unit,
        })) || []
      );
    },

    getUnitChildren(unitId) {
      return this.unitRooms[unitId] || [];
    },

    getEmptyBuilding(id) {
      return {
        name: this.$t('projects.no_active_buildings'),
        type: NO_BUILDINGS,
        id: `${id} -1`,
      };
    },

    getEmptyUnit(id) {
      return { name: this.$t('projects.no_active_units'), type: NO_UNITS, id: `${id} -1` };
    },

    getEmptyRoom(id) {
      return { name: this.$t('projects.no_active_rooms'), type: NO_ROOMS, id: `${id} -1` };
    },

    getLink(item) {
      let routeName;

      switch (item.type) {
        case PROJECT:
          routeName = PROJECTS_DETAILED;
          break;
        case BUILDING:
          routeName = BUILDINGS_DETAILED;
          break;
        case UNIT:
          routeName = UNITS_DETAILED;
          break;
        case ROOM:
          routeName = ROOMS_DETAILED;
          break;
        default:
          routeName = '';
          break;
      }
      return { name: routeName, params: { id: item.originalId || item.id }, query: { prevPage: this.page } };
    },

    checkItemIsOpen(itemId) {
      return !!this.open.find(openItemId => openItemId === itemId);
    },

    checkIfNoUnits(type) {
      return type === NO_UNITS;
    },

    fetchChildren(entity) {
      switch (entity.type) {
        case PROJECT:
          return this.fetchBuildings(entity);
        case BUILDING:
          return this.fetchUnits(entity);
        case UNIT:
          return this.fetchRooms(entity);
        default:
          return Promise.resolve();
      }
    },

    fetchBuildings(project) {
      // eslint-disable-next-line no-param-reassign
      project.isLoading = true;

      return this.getBuildings(project.id)
        .then(async buildings => {
          if (!buildings.length) {
            this.$set(this.projectBuildings, project.id, [this.getEmptyBuilding(project.id)]);
            return;
          }

          this.$set(
            this.projectBuildings,
            project.id,
            buildings.map(building => ({
              ...building,
              type: BUILDING,
              originalId: building.id,
              id: `${project.id} ${building.id}`,
            }))
          );
        })
        .finally(() => {
          // eslint-disable-next-line no-param-reassign
          project.isLoading = false;
        });
    },

    fetchUnits(building) {
      // eslint-disable-next-line no-param-reassign
      building.isLoading = true;

      return this.getUnits(building.originalId)
        .then(async units => {
          if (units.length === 0) {
            const noData = [this.getEmptyUnit(building.id)];

            this.$set(this.buildingUnits, building.id, noData);
            return;
          }

          this.$set(
            this.buildingUnits,
            building.id,
            units.map(unit => ({ ...unit, type: UNIT, originalId: unit.id, id: `${building.id} ${unit.id}` }))
          );
        })
        .finally(() => {
          // eslint-disable-next-line no-param-reassign
          building.isLoading = false;
        });
    },

    fetchRooms(unit) {
      // eslint-disable-next-line no-param-reassign
      unit.isLoading = true;

      return this.getRooms(unit.originalId)
        .then(async rooms => {
          if (!rooms.length) {
            const noData = [this.getEmptyRoom(unit.id)];

            this.$set(this.unitRooms, unit.id, noData);
            return;
          }

          this.$set(
            this.unitRooms,
            unit.id,
            rooms.map(room => ({ ...room, type: ROOM, originalId: room.id, id: `${unit.id} ${room.id}` }))
          );
        })
        .finally(() => {
          // eslint-disable-next-line no-param-reassign
          unit.isLoading = false;
        });
    },
  },
};
</script>
