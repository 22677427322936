<template>
  <base-missing-data-page class="projects-missing-data" :url-img="$options.imageDataMissing">
    <template v-if="isArchive" #message>
      {{ $t('projects.archived_missing_message') }}
    </template>

    <template v-else #message>
      {{ $t('projects.missing_message') }}
    </template>
  </base-missing-data-page>
</template>

<script>
import BaseMissingDataPage from '@/components/BaseMissingDataPage.vue';

import imageDataMissing from '@/assets/imageMissingData/missing-data-projects.svg';

export default {
  name: 'ProjectsMissingData',

  components: { BaseMissingDataPage },

  props: {
    isArchive: { type: Boolean, default: false },
  },

  imageDataMissing,
};
</script>
